import { useSelector } from "react-redux";

export const SkillBar = ({ skill }) => {
  return (
    <div className="mb-2">
      <span className="font-semibold">{skill.name}</span>
      <div className="w-full bg-gray-300 rounded-full h-2 mt-1">
        <div
          className="bg-blue-500 h-2 rounded-full"
          style={{ width: `${skill.level}%` }}
        ></div>
      </div>
    </div>
  );
};

export const SkillCircle = ({ skill }) => {
  const radius = 30;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (skill.level / 100) * circumference;

  return (
    <div className="text-center mb-4">
      <svg height="80" width="80" className="block mx-auto">
        <circle
          stroke="#e5e7eb"
          strokeWidth="5"
          fill="transparent"
          r={radius}
          cx="40"
          cy="40"
        />
        <circle
          stroke="#3b82f6"
          strokeWidth="5"
          fill="transparent"
          r={radius}
          cx="40"
          cy="40"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
      <span className="font-semibold">{skill.name}</span>
      <p>{skill.level}%</p>
    </div>
  );
};

export const SkillDots = ({ skill }) => {
  const filledCircles = Math.round(skill.level / 20); // Calculer combien de cercles sont remplis
  const color = useSelector((state) => state.theme);

  return (
    <div className="flex   items-center mb-2">
      <span
        className={` w-1/2 break-words font-medium text-sm text-[${color.fontColor}] mr-2`}
      >
        {skill.name}
      </span>
      {/* Affichage de 5 cercles plus petits et rapprochés */}
      {[...Array(5)].map((_, index) => (
        <svg
          key={index}
          className="w-4 h-4 " // Taille plus petite et marge réduite
          viewBox="0 0 24 24"
        >
          <circle
            cx="12"
            cy="12"
            r="8" // Rayon plus petit pour les cercles
            fill={index < filledCircles ? color.fontColor : "#e5e7eb"} // Rempli ou non selon le niveau
          />
        </svg>
      ))}
    </div>
  );
};

export const SkillDisplay = ({ skill, displayType }) => {
  const filledCircles = skill.level; // Niveaux de compétence entre 1 et 5
  const color = useSelector((state) => state.theme);

  const renderDots = () => (
    <div className="flex  justify-between items-center mb-2">
      <span
        className={` break-words font-medium text-sm text-[${color.fontColor}] mr-2`}
      >
        {skill.name}
      </span>
      <div className="flex ">
        {[...Array(5)].map((_, index) => (
          <svg key={index} className="w-4 h-4" viewBox="0 0 24 24">
            <circle
              cx="12"
              cy="12"
              r="8"
              fill={index < filledCircles ? color.fontColor : "#e5e7eb"}
            />
          </svg>
        ))}
      </div>
    </div>
  );

  const renderGauge = () => (
    <div className="flex items-center mb-2">
      <span
        className={`w-1/2 break-words font-medium text-sm text-[${color.fontColor}] mr-2`}
      >
        {skill.name}
      </span>
      <div className="w-1/2 bg-gray-300 h-2 rounded-full">
        <div
          className="h-full rounded-full"
          style={{
            width: `${(filledCircles / 5) * 100}%`, // Ajusté pour un niveau entre 1 et 5
            backgroundColor: color.fontColor,
          }}
        />
      </div>
    </div>
  );

  const renderBars = () => (
    <div className="flex justify-between items-center mb-2">
      <span
        className={` break-words font-medium text-sm text-[${color.fontColor}] mr-2`}
      >
        {skill.name}
      </span>
      <div className="flex">
        {[...Array(5)].map((_, index) => (
          <div
            key={index}
            className={`h-2 w-6 mr-1 ${
              index < filledCircles ? `bg-[${color.fontColor}]` : "bg-gray-300"
            }`}
          />
        ))}
      </div>
    </div>
  );

  return (
    <>
      {displayType === "dots" && renderDots()}
      {displayType === "gauge" && renderGauge()}
      {displayType === "bars" && renderBars()}
    </>
  );
};
