// Moja.jsx
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import WrapperSpacing from "../../../Element/WrapperSpacing";
import {
  WrapperElementSide,
  WrapperMain,
} from "../../../Element/WrapperElementSide";
import {
  ItemsEducation,
  ItemsExperience,
  ItemSide,
} from "../../../Element/Items";
import {
  HomeIcon,
  MailIcon,
  PhoneIcon,
  UserIcon,
} from "../../../Element/Icons";
import { TextSide } from "../../../Element/Text";
import { SkillDisplay, SkillDots } from "../../../Element/SkillBar";
import { useOverflowCheck } from "../../../../hook/useOverflowCheck";
import ListElement from "../../../Element/ListElement";

function Moja({ setOverflow }) {
  const color = useSelector((state) => state.theme);
  const cvData = useSelector((state) => state.cv);
  const { sectionsOrder, sections } = cvData;
  const wrapperRefMain = useRef(null);
  const wrapperRefSide = useRef(null);

  const overflowData = useOverflowCheck([wrapperRefMain, wrapperRefSide], {
    templateOne: true,
    deps: sections,
  });

  useEffect(() => {
    setOverflow(overflowData);
  }, [overflowData, setOverflow]);

  // Fonctions pour rendre les différentes sections
  const renderSection = (sectionType) => {
    switch (sectionType) {
      case "personalinfo":
        return null; // Les informations personnelles sont déjà affichées dans le header
      case "description":
        return (
          sections.description.description && (
            <WrapperMain key={sectionType} title="Profile">
              <p className="text-sm break-words text-gray-800">
                {sections.description.description}
              </p>
            </WrapperMain>
          )
        );
      case "education":
        return (
          sections.education.length > 0 && (
            <WrapperMain
              className="flex flex-col"
              key={sectionType}
              title="Formations"
            >
              {sections.education.map((edu, index) => (
                <ItemsEducation key={index} data={edu} />
              ))}
            </WrapperMain>
          )
        );
      case "experience":
        return (
          sections.experience.length > 0 && (
            <WrapperMain key={sectionType} title="Expériences Professionnelles">
              {sections.experience.map((exp, index) => (
                <ItemsExperience key={index} data={exp} />
              ))}
            </WrapperMain>
          )
        );
      case "skills":
        return null; // Les compétences sont affichées dans la sidebar
      case "languages":
        return (
          sections.languages.length > 0 && (
            <WrapperMain key={sectionType} title="Langues">
              {sections.languages.map((item, index) => (
                <SkillDisplay skill={item} displayType="bars" />
              ))}
            </WrapperMain>
          )
        );
      case "interests":
        return (
          sections.interests.length > 0 && (
            <WrapperMain
              gap={10}
              space={false}
              key={sectionType}
              title="Centres d'intérêt"
            >
              {sections.interests.map((item, index) => (
                <ListElement>{item.item}</ListElement>
              ))}
            </WrapperMain>
          )
        );
      default:
        return null;
    }
  };

  return (
    <div className="grid-cols-12 grid size-full">
      <div
        ref={wrapperRefSide}
        className="col-span-4 pb-2 overflow-hidden px-6 bg-[#E2EAEF] relative"
      >
        {/* Header avec les informations personnelles */}
        <div className="flex gap-3 flex-col justify-center items-center text-white absolute h-44 left-1/2 -translate-x-1/2 w-[90%] rounded-b-3xl bg-[#336C96]">
          <h1 className="text-xl text-center font-bold">
            {sections.personalinfo.name || "Nom du candidat"}
          </h1>
          <h1 className="text-center">
            {sections.personalinfo.title || "Titre du candidat"}
          </h1>
        </div>

        <div className="pt-52">
          <WrapperSpacing gap={20}>
            <WrapperElementSide
              className="w-full"
              title="Informations Personnelles"
            >
              <div className="w-full flex py-4 flex-col space-y-3 font-semibold">
                <ItemSide>
                  <UserIcon />
                  <TextSide>
                    {sections.personalinfo.name || "Nom du candidat"}
                  </TextSide>
                </ItemSide>
                <ItemSide>
                  <MailIcon />
                  <TextSide>
                    {sections.personalinfo.email || "Email du candidat"}
                  </TextSide>
                </ItemSide>
                <ItemSide>
                  <PhoneIcon />
                  <TextSide>
                    {sections.personalinfo.phone || "Téléphone du candidat"}
                  </TextSide>
                </ItemSide>
                <ItemSide>
                  <HomeIcon />
                  <TextSide>
                    {sections.personalinfo.address || "Adresse du candidat"}
                  </TextSide>
                </ItemSide>
              </div>
            </WrapperElementSide>

            {sections.skills.length > 0 && (
              <WrapperElementSide title="Compétences">
                {sections.skills.map((skill, index) => (
                  <SkillDots key={index} skill={skill} />
                ))}
              </WrapperElementSide>
            )}
          </WrapperSpacing>
        </div>
      </div>

      <div
        ref={wrapperRefMain}
        className="p-4 col-span-8 h-[98%] flex flex-col space-y-4 overflow-hidden"
      >
        {sectionsOrder.map((sectionType) => renderSection(sectionType))}
      </div>
    </div>
  );
}

export default Moja;
