import React from "react";
import { useSelector } from "react-redux";

function ListElement({ children }) {
  const color = useSelector((state) => state.theme);

  return (
    <div className="flex flex-row items-center text-sm   gap-2">
      <div className={` size-4 bg-[${color.fontColor}]`} />
      {children}
    </div>
  );
}

export default ListElement;
