import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { HomeIcon, MailIcon, PhoneIcon, UserIcon } from "../Element/Icons";
import { ItemsEducation, ItemsExperience, ItemSide } from "../Element/Items";
import { TextSide } from "../Element/Text";

import WrapperSpacing from "../Element/WrapperSpacing";
import { SkillBar, SkillCircle, SkillDots } from "../Element/SkillBar";
import { WrapperElementSide, WrapperMain } from "../Element/WrapperElementSide";
import { useOverflowCheck } from "../../hook/useOverflowCheck";

function Template1({ getNext }) {
  const cvData = useSelector((state) => state.cv);
  const { sections, customization } = cvData;
  const color = useSelector((state) => state.theme);
  const [isOverflow, setIsOverflow] = useState(false);

  const wrapperRefMain = useRef(null);
  const wrapperRefSide = useRef(null);

  const [isOverflowing, setIsOverflowing] = useState(false);

  const overflowData = useOverflowCheck([wrapperRefMain, wrapperRefSide], {
    templateOne: true,
  });

  useEffect(() => {
    getNext(overflowData);
    console.log(
      "Éléments qui débordent main : ",
      overflowData.wrapper0?.elements
    );
    console.log(
      "Éléments qui débordent side : ",
      overflowData.wrapper1?.elements
    );
  }, [overflowData]);

  return (
    <div className="grid-cols-12 grid size-full ">
      <div
        ref={wrapperRefSide}
        className=" col-span-4 pb-2  overflow-hidden px-6 bg-[#E2EAEF]   relative"
      >
        <div className=" flex gap-3 flex-col justify-center items-center text-white absolute h-36 left-1/2 -translate-x-1/2 w-2/3 rounded-b-3xl bg-[#336C96]">
          <h1 className="text-xl font-bold">
            {cvData.sections.profile.name || "Nom du candidat"}
          </h1>
          <h1 className="">
            {" "}
            {cvData.sections.profile.title || "Nom du candidat"}
          </h1>
        </div>

        <div className="pt-52">
          <WrapperSpacing gap={20}>
            <WrapperElementSide
              className="w-full"
              title={"Information Personnel"}
            >
              <div className=" w-full flex py-4 flex-col space-y-3 font-semibold ">
                <ItemSide>
                  <UserIcon />
                  <TextSide> John doe </TextSide>
                </ItemSide>
                <ItemSide>
                  <MailIcon />
                  <TextSide>
                    mroivili.moustoifa@gmail.com ki k,nk jnze dezone deinzjd
                    dezo,d
                  </TextSide>
                </ItemSide>
                <ItemSide>
                  <PhoneIcon />
                  <TextSide>0639600518</TextSide>
                </ItemSide>
                <ItemSide>
                  <HomeIcon />
                  <TextSide>
                    24 Rue Digole <br />
                    Majicavo Koropa <br /> 97690 Koungou
                  </TextSide>
                </ItemSide>
              </div>
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
            <WrapperElementSide title={"Compétences"}>
              {cvData.sections.skills.map((skill, index) => (
                <SkillDots key={index} skill={skill} />
              ))}
            </WrapperElementSide>
          </WrapperSpacing>
        </div>
      </div>
      <div
        ref={wrapperRefMain}
        className=" p-4 col-span-8 h-[98%] overflow-hidden "
      >
        <WrapperMain className="" title={"Profile"}>
          <p className=" text-sm  text-gray-800">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores
          </p>
        </WrapperMain>
        <WrapperMain gap={20} className="" title={"Formations"}>
          <ItemsEducation />
          <ItemsEducation />
          <ItemsEducation />
          <ItemsEducation />
          <ItemsEducation />
        </WrapperMain>
        <WrapperMain gap={10} title={"Experience professionnels"}>
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
        </WrapperMain>
        <WrapperMain gap={10} title={"Experience professionnels"}>
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
          <ItemsExperience />
        </WrapperMain>
      </div>
    </div>
  );
}

export default Template1;
