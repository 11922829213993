import React, { useState, useRef, useEffect } from "react";

const TextFiller = () => {
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const container1Ref = useRef(null);
  const textContent ="Texte très long que tu veux répartir sur plusieurs divs...".repeat(10000); // Exemple de long texte

  useEffect(() => {
    // Fonction pour vérifier si la première div est pleine
    const fillDivs = () => {
      const container1 = container1Ref.current;

      if (container1) {
        let remainingText = textContent;
        let firstContainerText = "";
        let secondContainerText = "";

        // Remplir la première div jusqu'à ce qu'elle soit pleine
        for (let i = 0; i < textContent.length; i++) {
          firstContainerText += textContent[i];
          setText1(firstContainerText);
          if (container1.scrollHeight > container1.clientHeight) {
            firstContainerText = firstContainerText.slice(0, -1); // Supprimer dernier caractère qui dépasse
            secondContainerText = textContent.slice(i);
            break;
          }
        }

        setText1(firstContainerText);
        setText2(secondContainerText);
      }
    };

    fillDivs(); // Remplir lors du premier rendu
  }, [textContent]);

  return (
    <div>
      <div
        ref={container1Ref}
        style={{
          height: "150px",
          overflow: "hidden",
          border: "1px solid black",
          padding: "10px",
          marginBottom: "20px",
        }}
      >
        {text1}
      </div>
      <div
        style={{
          height: "150px",
          overflow: "hidden",
          border: "1px solid black",
          padding: "10px",
        }}
      >
        {text2}
      </div>
    </div>
  );
};

export default TextFiller;
