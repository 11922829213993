// SectionElement.jsx

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SectionType from "./SectionType";
import Accordion from "./Accordion";
import {
  addSectionItem,
  moveSectionUp,
  moveSectionDown,
  updateSectionItem,
  removeSectionItem,
  updatePersonalInfo,
  updateSingleSection,
} from "../../features/cvSlice";

function AddMore({ onAdd }) {
  return (
    <div className="flex w-full justify-end">
      <button
        onClick={onAdd}
        className="bg-sky-800 rounded-lg p-2 px-10 text-white"
      >
        Ajouter
      </button>
    </div>
  );
}

function SectionElement({
  title,
  type,
  index,
  totalSections,
  isOpen,
  onAccordionToggle,
}) {
  const dispatch = useDispatch();
  const sectionItems = useSelector((state) => state.cv.sections[type]);

  const isArray = Array.isArray(sectionItems);

  // États locaux pour l'ajout et la modification
  const [addingNewItem, setAddingNewItem] = useState(false);
  const [newItemData, setNewItemData] = useState(getDefaultSectionItem(type));

  const [editingItemIndex, setEditingItemIndex] = useState(null);
  const [editingItemData, setEditingItemData] = useState(null);

  // Fonction pour démarrer l'ajout
  const handleAdd = () => {
    if (isArray) {
      setAddingNewItem(true);
      setNewItemData(getDefaultSectionItem(type));
    }
  };

  // Fonction pour sauvegarder le nouvel élément
  const handleSaveNewItem = () => {
    // Valider les données
    const isValid = validateItemData(type, newItemData);
    if (isValid) {
      dispatch(addSectionItem({ sectionType: type, item: newItemData }));
      setAddingNewItem(false);
      setNewItemData(getDefaultSectionItem(type));
    } else {
      // Gérer les erreurs de validation
      alert("Veuillez remplir tous les champs obligatoires.");
    }
  };

  // Fonction pour annuler l'ajout
  const handleCancelNewItem = () => {
    setAddingNewItem(false);
    setNewItemData(getDefaultSectionItem(type));
  };

  const handleEditItem = (idx) => {
    setEditingItemIndex(idx);
    setEditingItemData({ ...sectionItems[idx] });
  };

  // Fonction pour sauvegarder les modifications
  const handleSaveEditedItem = () => {
    // Valider les données
    const isValid = validateItemData(type, editingItemData);
    if (isValid) {
      dispatch(
        updateSectionItem({
          sectionType: type,
          index: editingItemIndex,
          item: editingItemData,
        })
      );
      setEditingItemIndex(null);
      setEditingItemData(null);
    } else {
      alert("Veuillez remplir tous les champs obligatoires.");
    }
  };

  // Fonction pour annuler la modification
  const handleCancelEditItem = () => {
    setEditingItemIndex(null);
    setEditingItemData(null);
  };

  // Fonction pour supprimer un élément
  const handleRemoveItem = (idx) => {
    dispatch(removeSectionItem({ sectionType: type, index: idx }));
  };

  // Gestion du déplacement vers le haut
  const handleMoveUp = () => {
    if (index > 0) {
      dispatch(moveSectionUp(index));
    }
  };

  // Gestion du déplacement vers le bas
  const handleMoveDown = () => {
    if (index < totalSections - 1) {
      dispatch(moveSectionDown(index));
    }
  };

  // Définition de handleUpdate
  const handleUpdate = (updatedData, idx = null) => {
    if (type === "personalinfo") {
      dispatch(updatePersonalInfo(updatedData));
    } else if (type === "description") {
      dispatch(updateSingleSection({ sectionType: type, data: updatedData }));
    } else {
      if (idx !== null && idx !== undefined) {
        dispatch(
          updateSectionItem({
            sectionType: type,
            index: idx,
            item: updatedData,
          })
        );
      }
    }
  };

  return (
    <div className="border-b relative">
      {/* Boutons flèche */}
      <Accordion
        child={
          <div className="absolute right-10 top-1/2 -translate-y-1/2 flex space-x-1">
            <button
              onClick={handleMoveUp}
              disabled={index === 0}
              className={`bg-violet-900 hover:bg-violet-800 p-2 rounded-full ${
                index === 0
                  ? "text-gray-50 bg-violet-200 hover:bg-violet-300"
                  : "text-white bg-violet-900 hover:bg-violet-800"
              }`}
            >
              {/* SVG Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 6.75 12 3m0 0 3.75 3.75M12 3v18"
                />
              </svg>
            </button>
            <button
              onClick={handleMoveDown}
              disabled={index === totalSections - 1}
              className={`p-2 rounded-full ${
                index === totalSections - 1
                  ? "text-gray-50 bg-violet-200 hover:bg-violet-300"
                  : "text-white bg-violet-900 hover:bg-violet-800"
              }`}
            >
              {/* SVG Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 17.25 12 21m0 0-3.75-3.75M12 21V3"
                />
              </svg>
            </button>
          </div>
        }
        title={title}
        isOpen={isOpen}
        onToggle={onAccordionToggle}
      >
        {isArray ? (
          <>
            {/* Formulaire d'ajout */}
            {addingNewItem && (
              <div className="  rounded mb-2">
                <SectionType
                  type={type}
                  data={newItemData}
                  // Ne pas passer l'index ici
                  onUpdate={setNewItemData} // Mise à jour de l'état local
                  onSave={handleSaveNewItem}
                  onCancel={handleCancelNewItem}
                  isEditing={true}
                />
              </div>
            )}

            {/* Liste des éléments existants */}
            {sectionItems.map((item, idx) => (
              <div key={idx} className="mb-2">
                {editingItemIndex === idx ? (
                  // Formulaire de modification
                  <div className="  rounded">
                    <SectionType
                      type={type}
                      data={editingItemData}
                      onUpdate={setEditingItemData} // Mise à jour de l'état local
                      onSave={handleSaveEditedItem}
                      onCancel={handleCancelEditItem}
                      isEditing={true}
                    />
                  </div>
                ) : (
                  // Résumé de l'élément
                  <div className=" bg-gray-100 w-full p-2 rounded-lg   flex flex-col  items-center">
                    <div className="w-full">
                      {renderItemSummary(type, item)}
                    </div>
                    <div className="flex justify-end w-full space-x-2">
                      <button
                        onClick={() => handleEditItem(idx)}
                        className="text-blue-500 border border-dashed p-2 rounded-lg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={() => handleRemoveItem(idx)}
                        className="text-red-500  border border-dashed p-2 rounded-lg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}

            {/* Bouton "Ajouter" */}
            {!addingNewItem && <AddMore onAdd={handleAdd} />}
          </>
        ) : (
          // Pour les sections non tableau
          <SectionType
            type={type}
            data={sectionItems}
            onUpdate={(updatedData) => handleUpdate(updatedData)}
            onSave={handleSaveEditedItem}
            onCancel={handleCancelEditItem}
            isEditing={true}
          />
        )}
      </Accordion>
    </div>
  );
}

export default SectionElement;

// Fonction par défaut
function getDefaultSectionItem(type) {
  switch (type) {
    case "education":
      return {
        degree: "",
        institution: "",
        location: "",
        startDate: "",
        endDate: "",
        description: "",
      };
    case "experience":
      return {
        title: "",
        company: "",
        location: "",
        startDate: "",
        endDate: "",
        description: "",
      };
    case "skills":
      return { name: "", level: 50, type: "skill" };
    case "languages":
      return { name: "", level: 50, type: "skill" };
    case "interests":
      return { interest: "", type: "list" };
    // Ajoutez d'autres cas si nécessaire
    default:
      return {};
  }
}

// Fonction de validation
function validateItemData(type, data) {
  switch (type) {
    case "education":
      return (
        data.degree.trim() !== "" &&
        data.institution.trim() !== "" &&
        data.startDate.trim() !== "" &&
        data.endDate.trim() !== ""
      );
    case "experience":
      return (
        data.title.trim() !== "" &&
        data.company.trim() !== "" &&
        data.startDate.trim() !== "" &&
        data.endDate.trim() !== ""
      );
    case "skills":
      return data.name.trim() !== "";

    // Ajoutez d'autres cas selon les besoins
    default:
      return true;
  }
}

// Fonction de rendu du résumé
function renderItemSummary(type, item) {
  switch (type) {
    case "education":
      return (
        <div>
          <h3 className="font-bold text-indigo-900">
            {item.degree || "Diplôme non spécifié"}
          </h3>
          <p>
            {item.institution || "Établissement non spécifié"} |{" "}
            {item.location || "Établissement non spécifié"}{" "}
          </p>
          <p className="text-sm text-gray-800">
            {item.startDate || "Début"} - {item.endDate || "Fin"}
          </p>
        </div>
      );

    case "experience":
      return (
        <div>
          <h3 className="font-bold text-indigo-900">{item.title || "Poste non spécifié"}</h3>
          <p>{item.company || "Entreprise non spécifiée"}</p>
          <p>
            {item.startDate || "Début"} - {item.endDate || "Fin"}
          </p>
        </div>
      );

    case "skills":
      return (
        <div>
          <h3 className="font-bold text-indigo-900">
            {item.name || "Compétence non spécifiée"}
          </h3>
          <p>Niveau: {item.level}%</p>
        </div>
      );

    case "languages":
      return (
        <div>
          <h3 className="font-bold text-indigo-900">{item.name || "Langue non spécifiée"}</h3>
          <p>Niveau: {item.level}%</p>
        </div>
      );

    case "interests":
      return (
        <div>
          <h3 className="font-bold">
            {item.item || "Centre d'intérêt non spécifié"}
          </h3>
        </div>
      );

    case "description":
      return (
        <div>
          <p>{item.description || "Description non spécifiée"}</p>
        </div>
      );

    case "personalinfo":
      return (
        <div>
          <h3 className="font-bold">
            {item.firstName} {item.lastName}
          </h3>
          <p>Email: {item.email || "Email non spécifié"}</p>
          <p>Téléphone: {item.phone || "Téléphone non spécifié"}</p>
          <p>Adresse: {item.address || "Adresse non spécifiée"}</p>
        </div>
      );

    case "list":
      return (
        <div>
          <p>{item.item || "Élément de la liste non spécifié"}</p>
        </div>
      );

    default:
      return <div>Résumé indisponible</div>;
  }
}
