import React, { useRef, useState } from "react";
import Template1 from "./template-1";
import A4Wrapper from "./A4Wrapper";
import DivWrapper from "../Element/divWrapper";
import Moja from "./templates/moja/moja";
import MojaB from "./templates/moja/moja-b";
import MultiPageWrapper from "../Element/MultiPageCv";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import BottomSettings from "../Element/BottomSettings";

const CVPreview = (props) => {
  const [overflow, setOverflow] = useState({ wrapper0: {}, wrapper1: {} });
  const pdfRef = useRef();
  const handleExportPDF = async () => {
    const input = pdfRef.current;

    // Crée une instance de jsPDF avec les dimensions A4
    const pdf = new jsPDF("p", "mm", "a4");

    // Sélectionne toutes les pages A4Wrapper
    const pages = input.querySelectorAll(".a4-wrapper");

    for (let i = 0; i < pages.length; i++) {
      const canvas = await html2canvas(pages[i], { scale: 2 }); // Réduire l'échelle de 2 à 1
      const imgData = canvas.toDataURL("image/png", 1); // Utiliser JPEG avec compression
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (i > 0) {
        pdf.addPage();
      }

      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
    }

    pdf.save("cv.pdf");
  };

  return (
    <div {...props}>
      <div ref={pdfRef}>
        <MultiPageWrapper
          className="flex flex-col space-y-8"
          templateB={<MojaB overflow={overflow} />}
          overflow={overflow}
          templateA={<Moja setOverflow={setOverflow} />}
        />
      </div>
      <BottomSettings>
        <button
          onClick={handleExportPDF}
          className="export-button font-semibold p-4 rounded-lg  bg-sky-800 text-sky-50 "
        >
          Exporter en PDF
        </button>
      </BottomSettings>
    </div>
  );
};

export default CVPreview;
